import React from 'react';
import { STRING, ROLE } from '../../../../utils/Constant'
import Loader from '../../../presentationals/Loader/DotLoader.component'
import { emiDetails } from '../../../containers/Dashboard/DashboardActionCreator.component';
import ViewEmi from '../../../../images/rewampimages/viewemi.png'
import NoData from '../../../../images/nodata.png'

const userEmi = ({ reducingEmiData, emiPaidHandler, emiDetail, emiPaidChange, admin, emiLoader, userDetail, createPayLink,findCommonEmiId }) => (
    <div className="details-new " style={{ marginBottom: '20px',border:'1.5px solid #DBE9FF',borderRadius:'5px',marginTop:'20px',minHeight:'400px' }}>
        {emiLoader ?
            <Loader /> : ""}
        {emiLoader == false ?

            <div className="">
                {/* <div className="col-xs-12">
                    <h4 className="text-center"><br /><strong>CURRENT EMI</strong></h4>

                </div> */}
                <div className='row'>
                    <div className='col-sm-6 col-xs-6'>

                        <h4 style={{ marginLeft: '20px' }}><img src={ViewEmi} width={'30px'} />EMI Breakdown</h4>
                    </div>
                    {emiDetail != null && emiDetail != '' ?

                        <div className='col-sm-6 col-xs-6'>
                            <p className='text-right' style={{ marginTop: '20px' }}><strong>Fore Close Amount =</strong> {emiDetail.length > 0 ? emiDetail[emiDetail.length - 1].ONCLOSE != null & emiDetail[emiDetail.length - 1].ONCLOSE != undefined ? emiDetail[emiDetail.length - 1].ONCLOSE : null : null}</p>

                        </div>
                        : ""}
                </div>
                {userDetail.loanFrom !== "vreedhi" ?
                    <div>
                        {emiDetail != null && emiDetail != '' ?
                            <div className="col-xs-12 phocket-table-new" style={{ overflow: 'auto' }}>
                                <table className="text-center ">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Paid Date</th>
                                            <th>Amount</th>
                                            <th>Paid Amount</th>
                                            {/* <th>Status</th> */}
                                            <th>Pay on today</th>
                                            <th>Loan status</th>
                                            <th>Principle Amt</th>
                                            <th>Interest Amount</th>
                                            <th>Penalty</th>
                                            <th>Nach Bounce</th>
                                            <th>Penalty Gst</th>
                                            <th>Bounce Gst</th>
                                            <th>Emi Tenure</th>
                                            <th>Overdue Amount</th>
                                            <th>Payment Link</th>
                                            {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ? <th className='text-center'>Action</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {emiDetail != '' && emiDetail != null && emiDetail.length > 0 ?
                                            emiDetail.map((data, i) => {
                                                const paymentLink = findCommonEmiId(data?.emiId);
                                                return (
                                                    <tr key={i}>
                                                        <td>{data.PAYDATE}</td>
                                                        <td>{data.paidDate}</td>
                                                        <td>{data.AMOUNT}</td>
                                                        <td>{data.paidAmount}</td>
                                                        {/* <td>{data.STATUS}</td> */}
                                                        <td>{data.ONTODAY}</td>
                                                        <td>{data.loanStatus}</td>
                                                        <td>{data.princpleAmt !== null && data.princpleAmt !== undefined ? Number(data.princpleAmt).toFixed(2) : ""}</td>
                                                        <td>{data.InterestAmount !== null && data.InterestAmount !== undefined ? Number(data.InterestAmount).toFixed(2) : ""}</td>
                                                        <td>{data.penalty !== null && data.penalty !== undefined ? Number(data.penalty).toFixed(2) : ""}</td>
                                                        <td>{data.nachBounce !== null && data.nachBounce !== undefined ? Number(data.nachBounce).toFixed(2) : ""}</td>
                                                        <td>{data.penaltyGst !== null && data.penaltyGst !== undefined ? Number(data.penaltyGst).toFixed(2) : ""}</td>
                                                        <td>{data.bounceGst !== null && data.bounceGst !== undefined ? Number(data.bounceGst).toFixed(2) : ""}</td>
                                                        <td>{data.emiTenure}</td>
                                                        <td>{data.OVERDUEAMOUNT}</td>
                                                        {
                                                            data.ONTODAY !== '' && data.ONTODAY !== null && data.ONTODAY !== undefined ?
                                                          paymentLink == true ?  <td>Link Generated</td> :
                                                        data.loanStatus === 'Disbursed' || data.loanStatus === 'OnGoing' || data.loanStatus === 'Payment Overdue' ?
                                                        <td style={{fontSize:'13px',textWrap:'nowrap',textDecorationLine:'underline'}}><a style={{cursor:'pointer'}} onClick={() => createPayLink(data)}><i class="fa fa-link" aria-hidden="true"></i>&nbsp;Generate Link</a> </td>
                                                        : <td></td>
                                                    :<td></td>}
                                                        {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                            <td>
                                                                {/* {data.STATUS != 'Paid' ? */}
                                                                <div className="display-flex">
                                                                    <select className="form-control"  style={{width:'164px',height:'43px',borderTopRightRadius:0,borderBottomRightRadius:0}} onChange={e => emiPaidChange(e)}>
                                                                        <optgroup className="display-none">
                                                                            <option value="">Select Action Type</option>
                                                                        </optgroup>
                                                                        <optgroup label="Actions">
                                                                        {admin != null && admin.rolelist.indexOf(ROLE.ACTION_PAID) >= 0 ?
                                                                            <option value={STRING.PAID}>Paid</option>
                                                                            :""}
                                                                            {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                                                <option value={STRING.PAYMENT_OVERDUE}>OverDue</option>
                                                                                : ""}
                                                                            {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                                                <option value={STRING.DISBURSED}>Disburse</option>
                                                                                : ""}
                                                                        </optgroup>
                                                                    </select>
                                                                    <button style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,height:'43px',color:'#fff'}} onClick={e => emiPaidHandler(data)}  className='btn btn-primary'>Save</button>
                                                                </div>
                                                                {/* : null} */}
                                                            </td>
                                                            : null}
                                                    </tr>
                                                )
                                            })
                                            : ""}
                                    </tbody>
                                </table>

                            </div>
                            : ""}
                    </div>
                    : ""}
            </div>
            : ""}
        {userDetail !== undefined && userDetail.loanFrom === "vreedhi" && (userDetail.applicationStatus === "Disbursed" || userDetail.applicationStatus === "Payment Overdue" || userDetail.applicationStatus === "On Going") ?
            <div>
                {emiDetail != null && emiDetail != ''
                    ?
                    <div className="col-xs-12 phocket-table-new">
                        <p><strong>Fore Close Amount =</strong> {emiDetail.length > 0 ? emiDetail[emiDetail.length - 1].ONCLOSE != null & emiDetail[emiDetail.length - 1].ONCLOSE != undefined ? emiDetail[emiDetail.length - 1].ONCLOSE : null : null}</p>
                        <table className=" ">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>O/s at beginning</th>
                                    <th>Interest</th>
                                    <th>EWI Amount</th>
                                    <th>O/s at end</th>
                                    <th>Principal Repaid</th>
                                    <th>Pay on today</th>
                                    <th>Paid Amount</th>
                                    <th>Paid Date</th>
                                    {/* <th>Status</th> */}

                                    <th>Loan status</th>
                                    <th>Overdue Amount</th>
                                    {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ? <th>Action</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {emiDetail != '' && emiDetail != null && emiDetail.length > 0 ?
                                    emiDetail.map((data, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{data.PAYDATE}</td>
                                                <td>{data.outstandingBalAtBeg}</td>
                                                <td>{data.InterestAmount}</td>

                                                <td>{data.AMOUNT}</td>
                                                <td>{data.outstandingBalAtEnd}</td>
                                                <td>{data.PrincipalPaid}</td>
                                                <td>{data.ONTODAY}</td>
                                                <td>{data.paidAmount}</td>
                                                <td>{data.paidDate}</td>
                                                {/* <td>{data.STATUS}</td> */}

                                                <td>{data.loanStatus}</td>
                                                <td>{data.OVERDUEAMOUNT}</td>
                                                {admin != null && admin.rolelist.indexOf(ROLE.ACTION_AND_DISBURSE) >= 0 ?
                                                    <td>
                                                        {/* {data.STATUS != 'Paid' ? */}
                                                        <div className="display-flex">
                                                            <select className="lead-select" onChange={e => emiPaidChange(e)}>
                                                                <optgroup className="display-none">
                                                                    <option value="">Select Action Type</option>
                                                                </optgroup>
                                                                <optgroup label="Actions">
                                                                    <option value={STRING.PAID}>Paid</option>
                                                                    <option value={STRING.PAYMENT_OVERDUE}>OverDue</option>
                                                                    <option value={STRING.DISBURSED}>Disburse</option>
                                                                </optgroup>
                                                            </select>
                                                            <span className="fa fa-save loan-id-save-btn" onClick={e => emiPaidHandler(data)} />
                                                        </div>
                                                        {/* : null} */}
                                                    </td>
                                                    : null}
                                            </tr>
                                        )
                                    })
                                    : ""}
                            </tbody>
                        </table>

                    </div>
                    : ''}
            </div>
            : userDetail.loanFrom === "vreedhi" ? '' : <div className='text-center'><img style={{width:'30%'}} src={NoData}/></div>}

        <div className='' style={{ marginTop: '20px' }}>
            {reducingEmiData != null && reducingEmiData != ''
                ?
                <div className="phocket-table-new">
                    <h4><img src={ViewEmi} width={'30px'} />EMI Reducing Calculation</h4>
                    <table className=" " >
                        <thead>
                            <tr>
                                <th>{'EMI Amount'}</th>
                                <th>{'EMI Date'}</th>
                                <th>{'Interest Amt'}</th>
                                <th>{'Principle Amt'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reducingEmiData != '' && reducingEmiData != null && reducingEmiData.length > 0 ?
                                reducingEmiData.map((data, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{data.emiAmount}</td>
                                            <td>{data.emiDate}</td>
                                            <td>{data.interestAmt}</td>
                                            <td>{data.principleAmt}</td>

                                        </tr>
                                    )
                                })
                                : ""}
                        </tbody>
                    </table>

                </div>
                : ''}
        </div>

    </div>

)

export default userEmi;