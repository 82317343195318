import React from "react";
import { aadharVerificationDetails, aadharXmlData, getSourceOfEnach, panProfileDetails } from "../AdminActionCreator.component";
import { getAmlData, getkycBoxApi } from "../Analysis/analysisactionCreator";
import GreenTick from '../../../../images/rewampimages/greentick.png'
import UnverifiedLogo from '../../../../images/rewampimages/unverifiedCross.png'


class Checklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paninfo: false,
            aadharInfo: false,
            karzaData: '',
            aadharVerificationData: '',
            nachSourceData: '',
            amlData: '',
            aadharLinked:false

        }
    }
    componentDidMount() {
        const { allDetail, userDetail } = this.props;

        aadharXmlData(allDetail.userId, callBack => {
            if (callBack !== '' && callBack !== null) {

                if (callBack.status === true) {
                    this.setState({ aadharInfo: true })
                }


            }
        })
        panProfileDetails(allDetail.userId, callBack => {
            if (callBack !== null && callBack !== '' && callBack !== undefined) {

                this.setState({ karzaData: callBack, paninfo: true })
            } else {

            }
        })
        aadharVerificationDetails(allDetail.userId, callBack => {
            this.setState({ aadharVerificationData: callBack, })
        })
        this.getSource()
        this.kycDataNew()
    }
    getSource() {
        const { admin, userDetail, allDetail } = this.props
        if (userDetail.nachStatus == true) {

            getSourceOfEnach(userDetail.userId, callBack => {
                this.setState({ nachSourceData: callBack })
            })
        }
        getAmlData(allDetail.userId, callBack => {
            console.log(callBack)
            this.setState({ amlData: callBack })

        })
    }

     kycDataNew () {
        const { allDetail, userDetail } = this.props;

        getkycBoxApi(allDetail.userId, allDetail.applicationId, allDetail.loanId, callback => {
          this.setState({aadharLinked:callback.userApplicationKYCAnalysis.aadhaarLinkedMobile})
        })
    }

    render() {
        return (
            <div className="details-new">
                <div className="operation-inner-box">
                    <div className="operation-inner-box-head">
                        <div className="row">
                            <div className="col-sm-8 col-xs-8">
                                <b>Repeat Checklist</b>
                            </div>
                            <div className="col-sm-4 col-xs-4 text-right" style={{ color: '#267DFF' }}>
                            </div>
                        </div>
                    </div>
                    <table className="opertion-table" style={{ borderBottom: 'none' }} >
                        <tr>
                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Aadhaar Verified</td>
                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.aadharInfo  ? GreenTick : UnverifiedLogo} width='27px' /></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>PAN Verified</td>
                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.karzaData.status !== '' && this.state.karzaData.status==='Active' ? GreenTick : UnverifiedLogo} width='27px' /></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Aadhaar Linked No.</td>
                          
                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={ this.state.aadharLinked==='yes' ? GreenTick : UnverifiedLogo} width='27px' /></td>

                                {/* <p>{this.state.aadharVerificationData != null ? this.state.aadharVerificationData.mobile != null ? this.state.aadharVerificationData.mobile : "" : ""}</p> */}
                          
                        </tr>
                        <tr>
                            <td style={{ borderTop: 'none', borderLeft: 'none' }}>Mandate</td>
                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', }}>     
                                   <span className='' style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>{this.state.nachSourceData.signDesk ? 'Sign Desk' : this.state.nachSourceData.billDesk ? 'Billdesk' : this.state.nachSourceData.razorPay ? (this.state.nachSourceData.razorPay)?.toUpperCase() : this.state.nachSourceData.techProcess ? 'Techprocess' : ''}</span></td>
                        </tr>
                        <tr>
                            <td style={{ borderTop: 'none', borderLeft: 'none', borderBottom: 'none' }}>AML Check</td>
                            
                            <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none' }}><img src={this.state.amlData !== '' && this.state.amlData.amlCheckDone ? GreenTick : UnverifiedLogo} width='27px' /></td>

                            {/* <td style={{ borderTop: 'none', borderRight: 'none', borderLeft: 'none', color: this.state.operationsData !== '' && this.state.operationsData.aadhaarPercentMatch && this.state.operationsData.aadhaarPercentMatch >= 60 ? '#4CC78C' : '#FF5C5D' }}>{this.state.operationsData !== '' && this.state.operationsData.aadhaarPercentMatch ? this.state.operationsData.aadhaarPercentMatch : '0'}% Match</td> */}
                        </tr>
                    </table>
                </div>
            </div>
        )
    }

}
export default Checklist

