import axios from "axios";
import { APIS } from "../../../../utils/api-factory";

export const getAllEmployees = (callBack, error) => {
    axios.get(APIS.GET_ALL_EMPLOYEES)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllExpense = (sDate,eDate,callBack, error) => {
    axios.get(APIS.GET_ALL_EXPENSE + sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllExpenseCategory = (type,sDate,eDate,callBack, error) => {
    if(type == 'dateRequired'){

        axios.get(APIS.GET_ALL_EXPENSE_CATEGORY +"?sDate=" +sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
    }else if(type == 'notRequired'){
        axios.get(APIS.GET_ALL_EXPENSE_CATEGORY)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
    }
};

export const getAllExpenseSubCategory = (type,sDate,eDate,callBack, error) => {
    if(type == 'dateRequired'){
        axios.get(APIS.GET_ALL_EXPENSE_SUB_CATEGORY + "?sDate=" + sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
    }else if(type == 'notRequired'){
        axios.get(APIS.GET_ALL_EXPENSE_SUB_CATEGORY )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
    }
};

export const getAllDescription = (type,sDate,eDate,callBack, error) => {
    if(type == 'dateRequired'){

        axios.get(APIS.GET_ALL_DESCRIPTION + "?sDate=" + sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
    }else if(type == 'notRequired'){
        axios.get(APIS.GET_ALL_DESCRIPTION )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
    }
};

export const getExpenseTarget = (sDate,eDate,callBack, error) => {
    axios.get(APIS.GET_EXPENSE_TARGET + sDate + "&eDate=" + eDate)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveEmployeeDataForTarget = (data, callBack, error) => {
    axios.post(APIS.SAVE_EMPLOYEE_DATA_FOR_TARGET, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseData = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseCategory = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_CATEGORY, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseSubCategory = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_SUB_CATEGORY, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseDescription = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_DESCRIPTION, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveExpenseTarget = (data, callBack, error) => {
    axios.post(APIS.SAVE_EXPENSE_TARGET, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusiness = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const saveBusinessPlanData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessPlanData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const getAllBusinessReturn = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_RETURN)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessReturnData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_RETURN_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessReturnData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_RETURN_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusinessTargetCategory = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_TARGET_CATEGORY)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessTargetCategoryData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_TARGET_CATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessTargetCategoryData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_TARGET_CATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusinessTargetSubCategory = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_TARGET_SUBCATEGORY)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessTargetSubCategoryData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_TARGET_SUBCATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessTargetSubCategoryData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_TARGET_SUBCATEGORY_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllBusinessTarget = (callBack, error) => {
    axios.get(APIS.GET_ALL_BUSINESS_TARGET)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const saveBusinessTargetData = (data, callBack, error) => {
    axios.post(APIS.SAVE_BUSINESS_TARGET_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const updateBusinessTargetData = (data, callBack, error) => {
    axios.post(APIS.UPDATE_BUSINESS_TARGET_DATA, data)
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteEmployeeById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EMPLOYEE_BY_ID + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_BY_ID + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseCategoryById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_CATEGORY_BY_ID + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseSubCategoryById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_SUBCATEGORY_BY_ID + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseDescriptionById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_DESCRIPTION_BY_ID + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const deleteExpenseTargetById = (id, callBack, error) => {
    axios.get(APIS.DELETE_EXPENSE_TARGET_BY_ID + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const getAllDepartmentList = (callBack, error) => {
    axios.get(APIS.GET_ALL_DEPARTEMENT_LIST )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const SaveDepartmentList = (data,callBack, error) => {
    axios.post(APIS.SAVE_DEPARTMENT_DATA,data )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};
export const UpdateDepartmentList = (data,callBack, error) => {
    axios.post(APIS.UPDATE_DEPARTMENT_DATA,data )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

export const DeleteDepartmentData = (id,callBack, error) => {
    axios.get(APIS.DELETE_DEPARTMENT_DATA + id )
        .then(res => {
            callBack(res.data);
        })
        .catch(e => {
            console.log(e);
            error(e.response?.data);
        });
};

