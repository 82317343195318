import React, { useEffect, useState } from "react";
import { getAllEmployees,deleteEmployeeById } from "../../targetactioncreator";
import Popup from '../../../../../presentationals/Popup/Popup.component';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import NodataImage from '../../../../../../images/nodata.png';
const EmpList = ({ editHandler }) => {
    const [loader,setLoader] =useState(false)
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [employeeData, setEmpData] = useState('')
    // const employeeData = {
    //     "employeeName": "Deepanshu",
    //     "employeeType": "Full-Time",
    //     "employeeDepartment": "Engineering",
    //     "joiningDate": "2023-08-22",
    //     "salary": 75000.00,
    //     "variable": 5000.00,
    //     "otherComponent": 2000.00,
    //     "isActive": true,
    //     "inHandAmount": 65000.00,
    //     "taxDeducted": 10000.00,
    //     "id":1,
    //     "employeeID":1
    // };

    const handleToggle = () => { };
    const handleRestart = () => { };
    const handlebtnclick = (type,data) => { 
        console.log(data)
        setLoader(true)
        deleteEmployeeById(data?.id,(callback)=>{
            console.log(callback)
            setLoader(false)
            if(callback.status == '200'){
                getEmployees()
            }else{
                setPopup(true);
                setPopupStatus('Please try again later!');
                removePopup();
            }
        })   
    };
    
    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const view = () => { };
    const millisecondsToMinutes = () => { };
    useEffect(() => {
        getEmployees()
    }, [])

    const getEmployees =()=>{
        setLoader(true)
        getAllEmployees(callback => {
            console.log(callback)
            setLoader(false)
            if(callback && callback?.status == 200){
                setEmpData(callback.data)
            }
        })
    }
    const closePopup = () => {
        setPopup(false);
    };
    return (
        <div className={`${window.innerWidth > 1800 ? 'container-fluid' : 'container'} Executor`}>
            {
                loader ?
                <Loader />
            :''}
             {sidePopup ?
                <Popup popupStatus={popupStatus} closePopup={closePopup} />
                : ""}
            {employeeData !==''&& employeeData.length>0 ?
            <div className='' style={{ background: '#f2f2f2', padding: '10px', marginLeft: 'auto', marginRight: 'auto', borderRadius: '5px', overflow: 'auto', marginTop: '50px' }}>
                <div className="">
                    <table className='table-logs' style={{ marginBottom: '10px' }}>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Employee Type</th>
                                <th>Department</th>
                                <th>Joining Date</th>
                                <th>Salary</th>
                                <th>Variable</th>
                                <th>Other Components</th>
                                <th>Active</th>
                                <th>In-Hand Amount</th>
                                <th>Tax Deducted</th>

                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employeeData!==''&&employeeData.length>0 ? employeeData.map((data, i) => {
                                return (
                                    <tr style={{ background: '#fff', padding: '5px', borderRadius: '5px' }}>
                                        <td className='text-center'  >{data.employeeName}</td>
                                        <td>{data.employeeType}</td>
                                        <td>{data.employeeDepartment}</td>
                                        <td>{data.joiningDate}</td>
                                        <td>{data.salary}</td>
                                        <td>{data.variable}</td>
                                        <td>{data.otherComponent}</td>
                                        <td style={{ width: '80px' }}>
                                            <input readOnly type='checkbox' checked={data.isActive} />
                                        </td>
                                        <td>{data.inHandAmount}</td>
                                        <td>{data.taxDeducted}</td>

                                        <td>
                                            <i className="fa fa-pencil-square-o fa-lg" aria-hidden="true" onClick={() => editHandler(data)}></i>
                                        </td>
                                        <td>
                                            <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => handlebtnclick('delete', data)}></i>
                                        </td>
                                    </tr>
                                )
                            }):""}

                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="text-center" style={{marginTop: '50px'}}>
                <img src={NodataImage}/>
                </div>
            }
        </div>
    );
};

export default EmpList;
