import React, { useState } from "react";
import DepartmentTarget from "./adddepartmentTarget";
import DeptList from "./listDepartment";
const MainEmp = () => {
    const [viewType, setviewType] = useState('list')
    const [editData, seteditData] = useState('')

    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }



    }
    const ChangeScreen=(type)=>{
        seteditData('')
        setviewType(type)
    }
    return (
        <div className="container">
            <div style={{ display: 'flex', textAlign: 'center' }}>
                <div onClick={() => ChangeScreen('list')} style={{ color: viewType === 'list' ? '#fff' : '#000', background: viewType === 'list' ? '#006c67' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-list" ></i></div>&nbsp;&nbsp;
                <div onClick={() => ChangeScreen('add')} style={{ color: viewType === 'add' ? '#fff' : '#000', background: viewType === 'add' ? '#006c67' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-plus" ></i></div>

            </div>
            {viewType === 'list' ?
                <DeptList  editHandler={editHandler}/>
                : ""}
            {viewType === 'add' ?
                <DepartmentTarget editData={editData} />

                : ""}
        </div>
    )
}
export default MainEmp