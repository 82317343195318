import React, { useEffect, useState } from "react";
import { getExpenseTarget } from "../../targetactioncreator";
import BarChart from "../BarChart";
import NodataImage from '../../../../../../images/nodata.png'
import Loader from '../../../../../presentationals/Loader/Loader.component';
import Popup from '../../../../../presentationals/Popup/Popup.component';
import moment from "moment";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
const TargetList = ({ applyFilter,fromDate,toDate,ActiveMonthFilter,applyFilterState }) => {
    // const expenseData = {
    //     "categoryId": "123",
    //     "categoryName": "Utilities",
    //     "subCategoryId": "456",
    //     "subCategoryName": "Electricity",
    //     "targetAmount": 500.0,
    //     "targetPercentageASPerAUM": 5.0,
    //     "currentAmount": 450.0,
    //     "currentPercentageASPerAUM": 4.5,
    //     "deviationAmount": 50.0,
    //     "deviationPercentageASPerAUM": 0.5,
    //     "comment": "Regular monthly payment",
    //     "active": true,
    //     id: "2"
    // };    
    const [loader, setLoader] = useState(false);
    const [sidePopup, setPopup] = useState(false);
    const [popupStatus, setPopupStatus] = useState('');
    const [expenseData, setExepensedata] = useState('')
    const [showgraph,setshowGraph] =useState(false)
    const [graphData,setGraphData] =useState([])
    const [graphHeading,setGraphHeading] =useState('')
    const [openAccordion, setOpenAccordion] = useState(null);

     const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? null : id);
    };

    const targetData = ()=>{ 
        const formattedFromDate = moment(fromDate).format('DD-MM-YYYY');
    const formattedToDate = moment(toDate).format('DD-MM-YYYY');
        setLoader(true)
        getExpenseTarget(formattedFromDate,formattedToDate,callback => {
            setLoader(false)
            if(callback && callback?.status == 200 && callback?.data?.length > 0){
                setExepensedata(callback.data)
            }else{
                setExepensedata([])
            }
        })
        applyFilterState()
    }


    useEffect(() => {
        if(applyFilter == true || ActiveMonthFilter !== ''){
            targetData()
        }
    }, [applyFilter,ActiveMonthFilter])


    
    const removePopup = () => {
        setTimeout(() => {
            closePopup();
        }, 5000);
    };

    const closePopup = () => {
        setPopup(false);
    };


    const handleSelectedData =(data)=>{
        setGraphData([
            {
                name:'Target Amount',
                amount: data?.targetAmount || 0
            },
            {
                name:'Current Amount',
                amount: data?.currentAmount || 0
            },
            {
                name:'Deviation Amount',
                amount: data?.deviationAmount || 0
            },
        ])
        setGraphHeading(`Target Amount - ${data?.targetAmount || 0}`)
        setshowGraph(!showgraph)
    }

    const tableDatacheck = (data) => {
        if (data == null || data == undefined || data == '') {
            return '-'
        } else {
            return data
        }
    }

    return (
          <div className="projection-new">

          <div className="container-fluid Projection" style={{ padding: '20px 50px' }}>

              {loader ? <Loader /> : ''}
              {sidePopup ?
              <Popup popupStatus={popupStatus} closePopup={closePopup} />
              : ""}
              <div className="accords openaccord" style={{ background: '#fff' }}>
                  <div className="">
                      {expenseData && expenseData?.length > 0 ? expenseData.map((item, index) => (
                          <AccordionItem
                              key={index}
                              id={index}
                              isOpen={openAccordion === index}
                              toggleAccordion={toggleAccordion}
                              item={item}
                              expenseData={expenseData}
                              tableDatacheck={tableDatacheck}


                          />
                      )) :
                          <div className="text-center" style={{ marginTop: '20px', marginBottom: '20px' }}><img width={'30%'} src={NodataImage} /></div>
                      }

                  </div>

              </div>
          </div>


      </div>
    );
};

export default TargetList;

function AccordionItem({ id, isOpen, toggleAccordion, expenseData, item, tableDatacheck, editHandler, handleDelete ,ActiveMonthFilter}) {
    const graphData = [
        {
            name:'Target Amount',
            amount: item?.targetAmount || 0
        },
        {
            name:'Current Amount',
            amount: item?.currentAmount || 0
        },
        {
            name:'Deviation Amount',
            amount: item?.deviationAmount || 0
        },
    ]
    return (
        <div className={`accords accordsub ${isOpen ? 'openaccord' : ''}`}>
            {console.log(expenseData, 'expenseDataexpenseData')}
            <div className="heading table-responsive">
                <div className="box" onClick={() => toggleAccordion(id)} style={{ backgroundColor: isOpen ? 'black' : 'white' }}>
                    <h5 style={{ color: isOpen ? 'white' : 'black' }}>
                        {isOpen ? <ArrowUpward /> : <ArrowDownward />}
                    </h5>
                </div>
                <table className="table text-center tableheader">
                    <tbody>
                        {expenseData !== '' && expenseData.length > 0 ?
                        <>
                            <tr>
                                <td className='td1 subtd' style={{ width: '100px', textAlign: 'center' }}>Category Name</td>
                                <td className='td1 subtd' style={{ width: '100px', textAlign: 'center' }}>Sub-Category Name</td>
                                <td className='td1 subtd' style={{ width: '100px', textAlign: 'center' }}>Target Amount</td>
                               
                            </tr>
                            <tr>
                                <td style={{ width: '35%',backgroundColor:'#fff' }}><b>{tableDatacheck(item.categoryName)}</b></td>
                                <td style={{ width: '35%',backgroundColor:'#fff' }}><b>{tableDatacheck(item.subCategoryName)}</b></td>
                                <td style={{ width: '35%',backgroundColor:'#fff' }}><b>{tableDatacheck(item.targetAmount)}</b></td>
                               
                            </tr>
                            </>
                            : ""}

                    </tbody>
                </table>
            </div>

            {isOpen && (
                <div className="bottomtable">
                    <div className={`subbottom table-responsive ${isOpen ? 'scrollanimation' : ''}`} style={{ overflow: 'hidden', border: 'none',padding:'0px 15px' }}>
                        <div className="row">
                            <div className="col-md-6 col-xs-12" style={{ padding: '10px 8px' }}>
                                <div className="tableedit" style={{ boxShadow: '3px 3px 9px 1px #c4c1c1', borderRadius: '6px', height: window.innerWidth > 1800 ? '430px' : '366px', padding: '10px' ,overflowY:'auto'}}>
                                    <div className="phocket-table-new" style={{ overflowX: 'auto', marginTop: '0px' }}>
                                        <table>
                                            <tbody>
                                
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Target % AUM</th>
                                                    <td>{tableDatacheck(item.targetPercentageASPerAUM) + '%'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Current Amount</th>
                                                    <td>{tableDatacheck(item.currentAmount)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Current % AUM</th>
                                                    <td>{tableDatacheck(item.currentPercentageASPerAUM) + '%'}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Deviation Amount</th>
                                                    <td style={{color:item.deviationAmount < 0 ? 'red':'green'}}>{tableDatacheck(item.deviationAmount)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Deviation % AUM</th>
                                                    <td style={{color:item.deviationAmount < 0 ? 'red':'green'}}>{tableDatacheck(item.deviationPercentageASPerAUM) + '%'}</td>
                                                </tr>
                                              
                                                <tr>
                                                    <th style={{ padding: '20px 10px' }}>Comment</th>
                                                    <td>{tableDatacheck(item.comment)}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12" style={{ padding: '10px 8px' }}>
                                <div className="tableedit" style={{ boxShadow: '3px 3px 9px 1px #c4c1c1', borderRadius: '6px', height: window.innerWidth > 1800 ? '430px' : '366px' }}>
                                    {/* <div id="chart"> */}
                                        {/* <ReactApexChart options={options} series={item.graphData} type="line" height={350} /> */}
                                    {/* </div> */}
                                        <BarChart sectionType={'expenseTargetListing'} Data={graphData} ActiveMonthFilter={ActiveMonthFilter} Heading={''}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>);
}